import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
// import Skills, {SkillItem, SkillProgress, SkillTitle, SkillValue, SkillProgressBar} from "../components/skills";

const SkillProgressBar = styled.span.attrs(({ progress }) => {
    // console.log((100 - progress)+"%");
    return ({
        right: (100 - progress) + "%"
    })
})`
    right: ${props => props.right};
`

export default () => {
    return (
        <Layout>
            <section class="section home__about" id="about">
                <div class="container">
                    <div className="row">
                        <div className="col col--sm-8 col--sm-offset-2 col--md-12 col--md-offset-0 col--lg-10 col--lg-offset-1">
                            <div className="row">
                                <div className="col col--md-6">
                                    <h2 className="home__about-title">About me</h2>
                                </div>
                                <div className="col col--md-6">
                                    <p className="home__about-content">I'm a crazy software engineer with great passion for User eXperience.</p>
                                </div>
                                <div className="col col--md-6">
                                    <figure className="home__about-figure">
                                        <img src="./me.jpeg" alt="My photo" />
                                    </figure>
                                </div>
                                <div className="col col--md-6">
                                    <h3 className="home__about-skills-title">Skills</h3>
                                    <ul>
                                        <   li className="skills__item">
                                            <div className="skills__title">Team Work</div>
                                            <div className="skills__value">100%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="100"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">Javascript</div>
                                            <div className="skills__value">90%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="90"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">Software Architecture</div>
                                            <div className="skills__value">90%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="90"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">Design Patterns</div>
                                            <div className="skills__value">90%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="90"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">CSS in JS</div>
                                            <div className="skills__value">75%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="75"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">CI / CD</div>
                                            <div className="skills__value">70%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="75"></SkillProgressBar></div>
                                        </li>

                                        <li className="skills__item">
                                            <div className="skills__title">UX</div>
                                            <div className="skills__value">65%</div>
                                            <div className="skills__progress"><SkillProgressBar progress="65"></SkillProgressBar></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
